@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap");

html {
  font-size: 14px;
}

body {
  background-color: rgb(236, 236, 236);
  margin: 0;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e3e3e;
}